import { motion } from "framer-motion";
import Styles from "./PartnerDesc.module.css";
import { useTranslation } from "react-i18next";
import Instagram from "../../assets/icons/instagram-circle-white.svg";
import Website from "../../assets/icons/website.svg";
import Gosia from "../../assets/images/gosia.png";
import GosiaMobile from "../../assets/images/gosia_mobile.png";
import Ceran from "../../assets/images/ceran.png";
import Welness from "../../assets/images/wellness_design_logo_white.png";

function PartnerDesc() {
  const { t } = useTranslation();
  return (
    <motion.div
      className={Styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.25 }}
    >
      <div className={Styles.descContainer}>
        <img className={Styles.imgTitle} src={Welness} alt="" />
        <p className={Styles.desc}>{t(`partners.WELNESS_DESIGN_DESC`)}</p>
        <hr className={Styles.descDivider} />
        <div className={Styles.socialIconsContainer}>
          <a
            className={Styles.socialMediaLink}
            href="https://www.wellness-design.pl"
          >
            <img className={Styles.smIcon} src={Website} alt="Website" />
          </a>
          <a
            className={Styles.socialMediaLink}
            href="https://www.instagram.com/wellness.design.pl"
          >
            <img className={Styles.smIcon} src={Instagram} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={Styles.imgContainer}></div>
      <div className={Styles.descContainer}>
        <h3 className={Styles.title}>{t(`partners.GOSIA_BABILONSKA_TITLE`)}</h3>
        <div className={Styles.imgContainerMobile}>
          <img className={Styles.img} src={GosiaMobile} alt="" />
        </div>
        <p className={Styles.desc}>{t(`partners.GOSIA_BABILONSKA_DESC`)}</p>
        <hr className={Styles.descDivider} />
        <div className={Styles.socialIconsContainer}>
          <a
            className={Styles.socialMediaLink}
            href="https://www.instagram.com/babilonfit/"
          >
            <img className={Styles.smIcon} src={Instagram} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={Styles.imgContainer}>
        <img className={Styles.img} src={Gosia} alt="" />
      </div>
      <div className={Styles.descContainer}>
        <h3 className={Styles.title}>{t(`partners.LUKASZ_CERAN_TITLE`)}</h3>
        <div className={Styles.imgContainerMobile}>
          <img className={Styles.img} src={Ceran} alt="" />
        </div>
        <p className={Styles.desc}>{t(`partners.LUKASZ_CERAN_DESC`)}</p>
        <hr className={Styles.descDivider} />
        <div className={Styles.socialIconsContainer}>
          <a
            className={Styles.socialMediaLink}
            href="https://www.instagram.com/ceranlukasz/"
          >
            <img className={Styles.smIcon} src={Instagram} alt="Instagram" />
          </a>
        </div>
      </div>
      <div className={Styles.imgContainer}>
        <img className={Styles.img} src={Ceran} alt="" />
      </div>
    </motion.div>
  );
}

export default PartnerDesc;
